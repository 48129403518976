body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Black.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Black\ Italic.otf");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Extrabold.otf");
  font-weight: 800;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Extrabold\ Italic.otf");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Bold.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Bold\ Italic.otf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Demibold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Demibold\ Italic.otf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Medium\ Italic.otf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Book.otf");
  font-weight: 450;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Book\ Italic.otf");
  font-weight: 450;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Regular\ Italic.otf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Semilight.otf");
  font-weight: 350;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Semilight\ Italic.otf");
  font-weight: 350;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Light.otf");
  font-weight: 350;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Light\ Italic.otf");
  font-weight: 350;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Ultralight.otf");
  font-weight: 200;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Ultralight\ Italic.otf");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Thin.otf");
  font-weight: 150;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Thin\ Italic.otf");
  font-weight: 150;
  font-style: italic;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Hairline.otf");
  font-weight: 100;
}
@font-face {
  font-family: "Real Text Pro";
  src: url("./fonts/FontFont\ -\ Real\ Text\ Pro\ Hairline\ Italic.otf");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Real Head Pro";
  src: url("./fonts/FontFont\ -\ Real\ Head\ Pro\ Extrabold.otf")
    format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "Real Head Pro";
  src: url("./fonts/FontFont\ -\ Real\ Head\ Pro\ Demibold.otf")
    format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url(./fonts/IBMPlexMono-Regular.ttf) format("truetype");
}
